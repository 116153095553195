import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider, teamsTheme, mergeThemes } from '@fluentui/react-northstar';

import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import {Providers, SimpleProvider, ProviderState} from '@microsoft/mgt-element';
import { initializeIcons } from '@fluentui/react';

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: "753df0f1-6760-4307-8199-09411dfeed12"
  }
};

const pca = new PublicClientApplication(configuration);

Providers.globalProvider = new SimpleProvider(async (scopes: string[]) => {
  const activeAccount = pca.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = pca.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in outside of the context of MsalProvider
        */   
       throw new Error("No active account")
    }
    const request = {
        scopes: ["User.Read", "Chat.Read"],
        account: activeAccount || accounts[0]
    };

    try {
      const authResult = await pca.acquireTokenSilent(request);

      if (!authResult.accessToken){
        console.error("No access token available")
        throw new Error("No access token");
      }
  
      return authResult.accessToken
    } catch(error: any) {
      if (error.errorCode === "interaction_required"){
        await pca.acquireTokenRedirect(request);
      }

      if (error.errorCode === "no_tokens_found"){
        await pca.acquireTokenPopup(request);
      }

      console.error(error.message);

      throw error;
    }

    
});

// set state to signal to all components to start calling graph
Providers.globalProvider.setState(ProviderState.SignedIn)

initializeIcons();

const theme = {
  componentVariables: {
    // 💡 `colorScheme` is the object containing all color tokens
    ChatWindow: ({ colorScheme } : any) => ({
      backgroundColor: colorScheme.brand.background3
    }),
  },
  componentStyles: {
    ChatWindow: {
      // 🚀 We recomend to use `colorScheme` from variables mapping
      root: ({ variables } : any) => ({
        backgroundColor: variables.backgroundColor,
      }),
    },
  },
}

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <Provider theme={mergeThemes(teamsTheme, theme)}>
        <App />
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
