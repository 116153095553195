import { Alert, Flex, Header, Form, FormInput, FormDatepicker, FormButton, FormDropdown } from '@fluentui/react-northstar';
import { MaskedTextField } from '@fluentui/react';
import { useState } from 'react';
import { ExportService } from './ExportService';

interface IChatExportFormProps {
    hasValidLicenseKey: boolean
    conversationTitle: string
    conversationSelected: boolean
    defaultFilename: string
    getMessages: (startDate: Date | null, endDate: Date | null) => Promise<[]>
    onError: (error: any) => void,
    showSettings: () => void
}

export const ChatExportForm = (props: IChatExportFormProps) => {
    const entireConversationText = "Entire conversation";
    const timePeriodText = "Messages between time period";

    const csvFileFormat = "CSV";
    const jsonFileFormat = "JSON"
    const pngFileFormat = "PNG"

    const [exportDuration, setExportDuration] = useState(entireConversationText);
    const [fileFormat, setFileFormat] = useState("CSV");
    const [fileName, setFileName] = useState<null | string>(null);
    const [chatStartDate, setChatStartDate] = useState<Date>(new Date());
    const [chatEndDate, setChatEndDate] = useState<Date>(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
    const [chatStartTime, setChatStartTime] = useState<string>(`${new Date().getHours()}:${new Date().getMinutes()}`);
    const [chatEndTime, setChatEndTime] = useState<string>(`${new Date().getHours()}:${new Date().getMinutes()}`);

    const [startTimeError, setStartTimeError] = useState<string | undefined>(undefined);
    const [endTimeError, setEndTimeError] = useState<string | undefined>(undefined);

    const [isExporting, setIsExporting] = useState(false);

    return <Flex column hAlign="center">
        <Alert
            hidden={props.hasValidLicenseKey}
            warning
            actions={[
                {
                    content: 'Add license key',
                    primary: true,
                    key: 'settings',
                    styles: { margin: '10px 0 10px 10px'},
                    onClick: () => {
                        props.showSettings();
                    }
                }
            ]}
            content={<p>Add a license key to export more than 5 messages. <a href="https://littleappyco.gumroad.com/l/chatexporter" rel="noreferrer" target="_blank">See pricing</a></p>}
            styles={{ marginBottom: '10px' }}
        />
        <Header content={`Export chat`} styles={{ margin: 0 }} />
        <p style={{ color: "#ABABAB" }}>{props.conversationTitle}</p>
        <Form>
            <FormInput label="File name" name="fileName" placeholder={props.defaultFilename} disabled={!props.conversationSelected} onChange={(_, value) => setFileName(value?.value ?? props.defaultFilename)} />
            <FormDropdown label="File format" defaultValue={csvFileFormat} items={[csvFileFormat, jsonFileFormat, pngFileFormat]} disabled={!props.conversationSelected} onChange={(_, dropdownValue) => {
                const fileFormat = dropdownValue.value as string
                setFileFormat(fileFormat);
            }} />
            <FormDropdown label="Export" onChange={(_, dropdownValue) => {
                const duration = dropdownValue.value as string
                setExportDuration(duration);
            }} defaultValue={entireConversationText} items={[entireConversationText, timePeriodText]} disabled={!props.conversationSelected} />

            {exportDuration === timePeriodText && <Flex column gap="gap.medium">
                <FormDatepicker label="Select a start date" onDateChange={(_, event) => {
                    if (event && event.value) {
                        setChatStartDate(event.value)
                    }
                }} defaultSelectedDate={chatStartDate} disabled={!props.conversationSelected} />

                <MaskedTextField errorMessage={startTimeError} label="Specify start time (in 24hr time)" mask="99:99" value={chatStartTime} disabled={!props.conversationSelected} onChange={(_, value) => {
                    if (value) {
                        setChatStartTime(value)
                    }
                }
                } />

                <FormDatepicker label="Select an end date" defaultSelectedDate={chatEndDate} disabled={!props.conversationSelected} onDateChange={(_, event) => {
                    if (event && event.value) {
                        setChatEndDate(event.value)
                    }
                }} />

                <MaskedTextField errorMessage={endTimeError} label="Specify end time (in 24hr time)" mask="99:99" value={chatEndTime} disabled={!props.conversationSelected} onChange={(_, value) => {
                    if (value) {
                        setChatEndTime(value)
                    }
                }
                } />
            </Flex>
            }

            <div style={{ textAlign: "center" }}>
                <FormButton primary content="Export chat" loading={isExporting} disabled={!props.conversationSelected} onClick={async () => {
                    try {
                        setIsExporting(true);

                        if (isExporting) {
                            return;
                        }

                        let messages: any;
                        if (exportDuration === timePeriodText) {
                            setStartTimeError(undefined);
                            setEndTimeError(undefined);

                            if (!(/^[0-9][0-9]:[0-9][0-9]/.test(chatStartTime))) {
                                setStartTimeError("Please enter a valid start time");
                                setIsExporting(false);
                                return;
                            }
                            if (!(/^[0-9][0-9]:[0-9][0-9]/.test(chatEndTime))) {
                                setEndTimeError("Please enter a valid end time");
                                setIsExporting(false);
                                return;
                            }

                            let start = chatStartDate;
                            let splitStartTime = chatStartTime!.split(":");
                            start.setHours(Number(splitStartTime[0]), Number(splitStartTime[1]));

                            let end = chatEndDate;
                            let splitEndTime = chatEndTime!.split(":");
                            end.setHours(Number(splitEndTime[0]), Number(splitEndTime[1]));

                            messages = await props.getMessages(start, end);
                        } else {
                            messages = await props.getMessages(null, null);
                        }

                        if (fileFormat === csvFileFormat) {
                            await ExportService.exportCsv(messages, fileName ?? props.defaultFilename);
                        } else if (fileFormat === jsonFileFormat) {
                            await ExportService.exportJson(messages, fileName ?? props.defaultFilename);
                        } else if (fileFormat === pngFileFormat) {
                            await ExportService.exportPng(fileName ?? props.defaultFilename);
                        }

                        setIsExporting(false);
                    } catch (err: any) {
                        props.onError(err);
                        setIsExporting(false);
                    }
                }} />
            </div>
        </Form>
    </Flex>
}