import { Dialog, Flex, Avatar, Header, Divider, List, Button, Chat, Loader, UserFriendsIcon, ContactGroupIcon, QuestionCircleIcon, CalendarIcon, FormInput } from '@fluentui/react-northstar';
import { Link } from '@fluentui/react';
import { useMsal } from "@azure/msal-react";
import { Providers } from '@microsoft/mgt-element';
import { PageIterator } from '@microsoft/microsoft-graph-client';
import { useEffect, useState } from 'react';
import { prepScopes } from '@microsoft/mgt';
import { useLocalStorage, StorageKeys } from './localStorage';

import moment from 'moment-timezone';
import { ChatExportForm } from './ChatExportForm';

export const AuthenticatedApp = () => {
    const { instance } = useMsal();
    const [userId, setUserId] = useState("");
    const [chats, setChats] = useState<any>([]);
    const [selectedChatIndex, setSelectedChatIndex] = useState<any>(null);
    const [chatMessages, setChatMessages] = useState<any>([]);
    const [selectedChat, setSelectedChat] = useState<any>(undefined);
    const [isLoadingChatList, setIsLoadingChatList] = useState(true);
    const [chatIterator, setChatIterator] = useState<PageIterator | null>(null);

    const [errorMessage, setErrorMessage] = useState(null);
    const [showSettings, setShowSettings] = useState(false);

    const [isVerifyingLicenseKey, setIsVerifyingLicenseKey] = useState(false);
    const [validatedLicenseKey, setValidatedLicenseKey] = useLocalStorage(StorageKeys.licenseKey, null);
    const [licenseKeyInput, setLicenseKeyInput] = useState(validatedLicenseKey ?? "");
    const [licenseValidationError, setLicenseValidationError] = useState<string | undefined>(undefined);
    const [licenseValidated, setLicenseValidated] = useState(false);

    useEffect(() => {
        loadUserDataAndChats();

        async function loadUserDataAndChats() {
            try {
                const response = await Providers.globalProvider.graph.client
                    .api('me')
                    .middlewareOptions(prepScopes('user.read'))
                    .get();

                setUserId(response.id);
                setChats([])
                getChatList();
            } catch (err: any) {
                setErrorMessage(err.message);
            }
        }

        async function getChatList() {
            try {
                const response = await Providers.globalProvider.graph.client
                    .api('me/chats')
                    .expand('members')
                    .top(30)
                    .middlewareOptions(prepScopes('Chat.Read'))
                    .version('beta')
                    .get();

                var chatIterationCount = 30;

                let callback = (chatItem: any) => {
                    var icon = <QuestionCircleIcon />

                    if (chatItem.chatType === "oneOnOne") {
                        icon = <UserFriendsIcon />
                    } else if (chatItem.chatType === "group") {
                        icon = <ContactGroupIcon />
                    } else if (chatItem.chatType === "meeting") {
                        icon = <CalendarIcon />
                    }

                    const timestamp = moment.utc(chatItem.lastUpdatedDateTime).local().format('YYYY-MM-DD h:mm A');
                    var topicName = chatItem.topic ?? "No topic name"

                    if (!chatItem.topic && chatItem.members.length > 0) {
                        var otherMembers = chatItem.members.map((member: any) => member?.displayName ?? "")
                        topicName = otherMembers.join(", ")
                    }

                    setChats((existingChats: any) => {

                        return [
                            ...existingChats,
                            {
                                key: chatItem.id,
                                header: topicName,
                                content: timestamp ?? "Never updated",
                                id: chatItem.id,
                                truncateHeader: true,
                                truncateContent: true,
                                media: (<Avatar icon={icon} />)
                            }
                        ];
                    });

                    chatIterationCount -= 1;

                    if (chatIterationCount === 0) {
                        chatIterationCount = 30;
                        return false;
                    } else {
                        return true;
                    }
                };

                var iterator = new PageIterator(Providers.globalProvider.graph.client, response, callback, { middlewareOptions: prepScopes('Chat.Read') });

                await iterator
                    .iterate();

                setChatIterator(iterator)
                setIsLoadingChatList(false);
            } catch (err: any) {
                setErrorMessage(err.message);
            }
        }

    }, []);

    async function getChatMessages(index: number, chatStartDate: Date | null, chatEndDate: Date | null) {
        try {
            var chatId = chats[index]["id"];

            const response = await Providers.globalProvider.graph.client
                .api(`me/chats/${chatId}/messages`)
                .version('beta')
                .middlewareOptions(prepScopes('Chat.Read'))
                .get();


            var messages: any = [];

            let callback = (message: any) => {
                if (chatStartDate
                    && chatEndDate) {
                    const lastModifiedDateTimeMoment = moment.utc(message.lastModifiedDateTime);
                    if (lastModifiedDateTimeMoment.isBefore(moment.utc(chatStartDate)) || lastModifiedDateTimeMoment.isAfter(moment.utc(chatEndDate))) {
                        console.log("Message more recent or older than chat start & end date")
                        return false;
                    }
                }

                if (!validatedLicenseKey && messages.length === 5) {
                    console.log("Free trial, limited to 5 messages");
                    return false;
                }

                if (message.messageType !== "message" || message.chatId !== chatId) {
                    return true;
                }

                messages = [
                    message,
                    ...messages
                ];

                return true;
            }

            var iterator = new PageIterator(Providers.globalProvider.graph.client, response, callback, { middlewareOptions: prepScopes('Chat.Read') });

            do {
                await (iterator)!
                    .iterate();
            } while (!iterator!.isComplete)

            const uiChatMessages = messages.map((msg: any) => convertToChat(msg));
            setChatMessages(uiChatMessages);

            return messages
        } catch (err: any) {
            setErrorMessage(err.message);
        }
    }

    function convertToChat(message: any) {
        const timestamp = moment.utc(message.lastModifiedDateTime).local().format('YYYY-MM-DD HH:mm:ss');
        const initials = message.from?.user?.displayName ?? message.from?.application.displayName ?? "System Message";
        const displayName = message.from?.user?.displayName ?? message.from?.application.displayName + " " + message.from?.application.applicationIdentityType ?? "System Message";

        return {
            gutter: <Avatar name={initials} />,
            message: (
                <Chat.Message
                    content={{
                        content: (
                            <div dangerouslySetInnerHTML={{ __html: message.body.content }} />
                        ),
                    }}
                    author={displayName}
                    timestamp={timestamp}
                    mine={userId === message.from?.user?.id}
                />
            ),
            contentPosition: userId === message.from?.user?.id ? 'end' : 'start',
            key: message.id
        }
    }

    async function getNextSetOfChats() {
        try {
            if (!chatIterator) {
                return;
            }

            await chatIterator!
                .iterate();
        } catch (err: any) {
            setErrorMessage(err.message);
        }
    }

    return <div>
        <Flex vAlign="center" space="between" styles={{ height: '5vh', padding: '0em 1em 0em 1em' }}>
            <Header content="Chat Exporter" />
            <Flex gap="gap.small">
                <Button onClick={() => setShowSettings(true)}>
                    Settings
                </Button>
                <Button onClick={() => {
                    setValidatedLicenseKey(null);
                    instance.logoutPopup()
                }}>
                    Sign out
                </Button>
            </Flex>
        </Flex>

        <Dialog
            confirmButton="OK"
            content={errorMessage}
            header="Error"
            open={errorMessage !== null}
            onConfirm={() => setErrorMessage(null)}
        />

        <Dialog
            header="Settings"
            cancelButton="Done"
            content={
                <Flex column gap="gap.medium">
                    <p>Need a license key? <a href="https://littleappyco.gumroad.com/l/chatexporter" rel="noreferrer" target="_blank">Get one here</a></p>
                    <FormInput
                        errorMessage={licenseValidationError}
                        label="License key"
                        name="licenseKey"
                        id="licenseKey"
                        value={licenseKeyInput}
                        required
                        showSuccessIndicator={licenseValidated}
                        onChange={(_, value) => setLicenseKeyInput(value?.value ?? "")}
                    />

                    <Loader hidden={!isVerifyingLicenseKey} />

                    <Button hidden={!validatedLicenseKey} onClick={() => {
                        setValidatedLicenseKey(null);
                        setLicenseKeyInput(null);
                    }}>Remove key</Button>

                    <Button
                        hidden={isVerifyingLicenseKey || validatedLicenseKey !== null} primary onClick={async () => {
                            setIsVerifyingLicenseKey(true);
                            setLicenseValidationError(undefined);

                            const keyToTry = licenseKeyInput;

                            try {

                                var response = await fetch('https://api.gumroad.com/v2/licenses/verify?product_permalink=chatexporter&increment_uses_count=false&license_key=' + encodeURIComponent(keyToTry), {
                                    method: "POST"
                                });

                                var responseBody = await response.json();

                                if (response.status === 404) {
                                    setLicenseValidationError("Invalid license key")
                                } else if (response.status !== 200) {
                                    setLicenseValidationError("Error occurred while verifying key")
                                } else if (response.status === 200 && !responseBody.purchase.refunded && !responseBody.purchase.disputed) {
                                    setLicenseValidated(true);

                                    //Persist key
                                    setValidatedLicenseKey(keyToTry);
                                } else {
                                    setLicenseValidationError(undefined)
                                }

                                setIsVerifyingLicenseKey(false);
                            } catch (err) {
                                setLicenseValidationError("Error occurred while verifying key")
                            }
                        }}>Verify license key</Button>

                    <Flex gap="gap.medium" styles={{marginTop: '10px'}}>
                        <Link href="https://airtable.com/shr7IBM12jufebvRl" target="_blank">Submit feedback</Link>

                        <Link href="https://airtable.com/shrf0pxkKkEMelXai" target="_blank">Support request</Link>
                    </Flex>
                </Flex>
            }
            open={showSettings}
            onCancel={() => {
                setLicenseValidationError(undefined)
                setShowSettings(!showSettings)
            }}
        />

        <Divider />

        {isLoadingChatList && <Loader label="Fetching chats..." />}

        {!isLoadingChatList && <div className="row">
            <div style={{ height: '94vh', float: 'left', width: '25%', overflowY: "scroll" }}>
                <List selectable defaultSelectedIndex={0} items={chats}
                    style={{ overflowY: "scroll", backgroundColor: '#fff' }}
                    selectedIndex={selectedChatIndex}
                    truncateContent={true}
                    truncateHeader={true}
                    styles={{ overflowY: "scroll" }}
                    onSelectedIndexChange={async (e, data) => {
                        const newIndex = data?.selectedIndex ?? 0;
                        setChatMessages([]);
                        setSelectedChatIndex(newIndex);
                        setSelectedChat(chats[newIndex]);
                    }} />
                <div style={{ textAlign: "center", margin: '8px' }} hidden={chatIterator?.isComplete() ?? false}>
                    <Button onClick={async () => await getNextSetOfChats()}>Load more</Button>
                </div>
            </div>
            <Divider vertical styles={{ margin: 0, paddingLeft: 0, float: 'left', height: '94vh' }} />
            <div style={{ height: '94vh', float: 'left', width: '70%' }}>
                <Flex column hAlign={"center"} style={{ paddingTop: 20, paddingBottom: 20, width: '100%', height: '100%' }}>
                    <ChatExportForm conversationTitle={selectedChat?.header ?? "No item selected"}
                        hasValidLicenseKey={validatedLicenseKey != null}
                        showSettings={() => setShowSettings(true)}
                        conversationSelected={selectedChat != null}
                        defaultFilename={selectedChat?.key ?? "filename"}
                        onError={(error) => setErrorMessage(error.message)}
                        getMessages={async (startDate: null | Date, endDate: null | Date) => {
                            var messages = await getChatMessages(selectedChatIndex, startDate, endDate);
                            return messages;
                        }} />
                </Flex>
            </div>
        </div>}

        <Chat id="element-to-print" style={{ display: 'none' }} items={chatMessages} />
    </div>
}