import { Header, Flex, DownloadIcon, FilesEmptyIcon, FilterIcon, Button, LockIcon } from '@fluentui/react-northstar';
import './App.css';
import { FeatureItem } from './FeatureItem';
import { AuthenticatedApp } from './AuthenticatedApp';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Link } from '@fluentui/react';

import { useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";

import { ApplicationInsights } from '@microsoft/applicationinsights-web'

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: '69589c06-4cd8-4133-a84e-17bfbd1a4d74'
    }
  });

  appInsights.loadAppInsights();
  appInsights.trackPageView();

  return (
    <div>
      <Router>
        <Switch>
          <Route path="/users">
            Users
          </Route>
          <Route path="/">
            <AuthenticatedTemplate>
              <AuthenticatedApp />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Flex gap="gap.large" column hAlign="center" vAlign="center" styles={{ height: '100vh' }}>
                <Header content="Chat Exporter for Teams" description="Export your Microsoft Teams conversations with ease" />
                <Flex column hAlign="start" gap="gap.medium">
                  <FeatureItem text="Easily export your Teams conversations" icon={<DownloadIcon size={"largest"} />} />
                  <FeatureItem text="Export to CSV, PNG or JSON files" icon={<FilesEmptyIcon size="largest" />} />
                  <FeatureItem text="Extract all messages in a chat, or specify a time window" icon={<FilterIcon size={"largest"} />} />
                  <FeatureItem text="Data processing occurs locally in your web browser" icon={<LockIcon size={"largest"} />} />
                </Flex>

                <Button onClick={() => {
                  instance.loginPopup({
                    prompt: "select_account",
                    scopes: ["User.Read", "Chat.Read"]
                  })
                }} primary styles={{ marginTop: '8pt' }}>{isAuthenticated ? "Get started" : "Sign in with your Teams account"}</Button>

<iframe width="560" height="315" src="https://www.youtube.com/embed/u9uXvyXY_2c" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>

                <p><Link href="https://littleappy.co/ms-apps/chat-exporter-for-microsoft-teams/privacy/" rel="noreferrer" target="_blank">Privacy policy</Link> | <Link href="https://littleappy.co/ms-apps/chat-exporter-for-microsoft-teams/terms-of-use/" rel="noreferrer" target="_blank">Terms of Use</Link> | <Link href="https://littleappyco.gumroad.com/l/chatexporter" rel="noreferrer" target="_blank">Pricing</Link></p>
              </Flex>
            </UnauthenticatedTemplate>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
