import html2canvas from 'html2canvas';

const csvWriter = require('csv-writer');

export class ExportService {
    static async exportPng(filename: string) {
        const element = document.getElementById('element-to-print')!;
        element.style.display = "flex";

        html2canvas(element!, {
            scrollY: -window.scrollY,
            allowTaint: true,
            useCORS: true,
            onclone: function (clonedDoc) {
                clonedDoc!.getElementById('element-to-print')!.style.display = 'visible';
            }
        })
            .then(canvas => {
                var url = canvas.toDataURL()
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${filename}.png`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode?.removeChild(link);
                element.style.display = "none";
            });
    }

    static async exportJson(messages: any, filename: string) {
        const url = window.URL.createObjectURL(
            new Blob([JSON.stringify(messages)]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `${filename}.json`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
    }

    static async exportCsv(messages: any, filename: string) {
        const createCsvStringifier = csvWriter.createObjectCsvStringifier;
        const csvStringifier = createCsvStringifier({
            header: [
                { id: 'id', title: 'ID' },
                { id: 'replyToId', title: 'REPLY_TO_ID' },
                { id: 'messageType', title: 'MESSAGE_TYPE' },
                { id: 'createdDateTime', title: 'CREATED_DATE_TIME' },
                { id: 'lastModifiedDateTime', title: 'LAST_MODIFIED_DATE_TIME' },
                { id: 'lastEditedDateTime', title: 'LAST_EDITED_DATE_TIME' },
                { id: 'subject', title: 'SUBJECT' },
                { id: 'summary', title: 'SUMMARY' },
                { id: 'chatId', title: 'CHAT_ID' },
                { id: 'channelIdentity', title: 'CHANNEL_IDENTITY' },
                { id: 'importance', title: 'IMPORTANCE' },
                { id: 'fromApplication', title: 'FROM_APPLICATION' },
                { id: 'fromDevice', title: 'FROM_DEVICE' },
                { id: 'fromUser', title: 'FROM_USER' },
                { id: 'contentType', title: 'CONTENT_TYPE' },
                { id: 'content', title: 'CONTENT' },
                { id: 'webUrl', title: 'WEB_URL' }
            ]
        });

        const records = messages.map((message: any) => {
            return {
                id: message.id,
                replyToId: message.replyToId,
                messageType: message.messageType,
                createdDateTime: message.createdDateTime,
                lastModifiedDateTime: message.lastModifiedDateTime,
                lastEditedDateTime: message.lastEditedDateTime,
                subject: message.subject,
                summary: message.summary,
                chatId: message.chatId,
                channelIdentity: message.channelIdentity,
                importance: message.importance,
                fromApplication: JSON.stringify(message.from.application),
                fromDevice: JSON.stringify(message.from.device),
                fromUser: JSON.stringify(message.from.user),
                contentType: message.body.contentType,
                content: message.body.content,
                webUrl: message.webUrl
            }
        });

        const url = window.URL.createObjectURL(
            new Blob([csvStringifier.getHeaderString() + csvStringifier.stringifyRecords(records)]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `${filename}.csv`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
    }
}